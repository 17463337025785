import React from "react";
import { Link, graphql } from "gatsby";
import Img from 'gatsby-image';
import { css } from "@emotion/core";
import { rhythm } from "../utils/typography";
import Layout from "../components/layout";
import Sidebar from "../components/sidebar";
import SEO from "../components/seo";

export default ({ data }) => {
  // console.log(data)
  const myTitle = "Max Elanders webbplats";
  return (
    <Layout>
      <SEO title={myTitle} description="Personliga tankar" />
      <div className="main">
        <h1
          css={css`
            display: block;
            border: none;
            margin-top:5 0;
          `}
        >
          Mina fem senaste:
        </h1>
        <div className="innerbox">
          <div className="inner">
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div className="preview" key={node.id}>
                <Link
                  to={node.fields.slug}
                  css={css`
                            text-decoration: none;
                            color: inherit;
                            display: flex;
                        `}
                >
                  {node.frontmatter.featuredImage && <Img className="preview_image" fixed={node.frontmatter.featuredImage.childImageSharp.fixed} />}
                  <div className="preview_text" ><h3
                    css={css`
                margin-bottom: ${rhythm(1 / 4)};
              `}
                  >
                    {node.frontmatter.title}{" "}
                  </h3>
                    <p>{node.frontmatter.excerpt}</p>
                  </div>
                </Link>
              </div>
            ))}
            <div><Link
              to="/blog/"
              css={css`
                float: right;
                margin-right: .5rem;
            `}
            >Alla artiklar
        </Link></div>
          </div>
          <Sidebar></Sidebar>
        </div>
      </div>
    </Layout >
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(limit: 5, sort: {fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY",locale: "sv")
            excerpt
            featuredImage {
              childImageSharp {
                fixed(height: 150, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed
                  tracedSVG
                }
              }
            }
          }
          fields {
              slug
          }
          excerpt
        }
      }
    }
  }
`