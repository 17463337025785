import React from 'react';
import { css } from "@emotion/core"
import Logo from '../images/loggan.svg';


function Sidebar() {
    return (<div className="sidebar">
        <span css={css`
            display: block;
            height: 120px;
            width: 120px;
            padding: 15px;
            margin:0 auto;
            background-color: ivory;
            border-radius: 50%;
            border: 5px solid #faf9f9;
            box-shadow: 0px 0px 15px #6f6f6f;
            margin-bottom:15px;
          `}>
            <Logo />
        </span>
        <p>
            Här skriver jag om sådant som intresserar mig, och kanske andra. Alla åsikter är mina egna, om jag inte uttryckligen skriver något annat.
        </p>
        <p>Vilka ämnen jag behandlat hittills ser du om du klickar på "ämnen" ovan.</p>
    </div>)
}

export default Sidebar;